import crypto from 'crypto'
import Cookies from 'js-cookie'

import {
  fetchLang,
  initAmplitude,
  setUserProperty,
  setAmplitudeUserId,
  sendAmplitudeData,
} from '../utils'

const extractGaCid = () => {
  const rawCid = Cookies.get('_ga')
  if (!rawCid) return ''

  return rawCid.split('.').slice(-2).join('.')
}
const generateMarketingId = () => extractGaCid() || crypto.randomBytes(16).toString('hex')

document.addEventListener('DOMContentLoaded', () => {
  initAmplitude()
  setUserProperty({ key: 'landing_language', value: fetchLang() })
  const marketingId = Cookies.get('userMarketingId')

  if (marketingId) {
    setAmplitudeUserId(marketingId)
    sendAmplitudeData('landing-visit', { visit_type: 'return' })
  } else {
    const userMarketingId = generateMarketingId()
    Cookies.set('userMarketingId', userMarketingId, { domain: process.env.DOMAIN })
    setAmplitudeUserId(userMarketingId)
    sendAmplitudeData('landing-visit', { visit_type: 'initial' })
  }

  document.querySelectorAll('[data-amplitude]').forEach(elem => elem.addEventListener('click', () => {
    const event = elem.getAttribute('data-event')
    const params = elem.getAttribute('data-params')
    sendAmplitudeData(event, JSON.parse(params))
  }))
})
