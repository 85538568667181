document.addEventListener('DOMContentLoaded', () => {
  const ppCloseBtn = document.querySelector('.js-close-pp')
  const ppAcceptBtn = document.querySelector('.js-accept-pp')
  const ppWrap = document.querySelector('.js-pp-wrap')
  const ppPopup = document.querySelector('.js-popup')
  const ppPopupCloseBtn = document.querySelector('.js-popupCloseBtn')

  if (!localStorage.getItem('ppAccepted') && ppPopup) {
    ppWrap.classList.add('shown')
    ppPopup.classList.remove('bottom')
  }

  if (localStorage.getItem('ppAccepted') && ppPopup) {
    ppPopup.classList.add('bottom')
  }

  if (ppCloseBtn) {
    ppCloseBtn.addEventListener('click', () => {
      ppWrap.classList.remove('shown')
      ppPopup.classList.add('bottom')
    })
  }

  if (ppAcceptBtn) {
    ppAcceptBtn.addEventListener('click', () => {
      ppWrap.classList.remove('shown')
      ppPopup.classList.add('bottom')
      localStorage.setItem('ppAccepted', '1')
    })
  }

  if (ppPopupCloseBtn) {
    ppPopupCloseBtn.addEventListener('click', () => {
      ppPopup.classList.add('hidden')
    })
  }
})
