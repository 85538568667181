import arrowUpSvg from '../../assets/svg/arrowUp.svg'
import arrowDownSvg from '../../assets/svg/arrowDown.svg'

document.addEventListener('DOMContentLoaded', () => {
  const btns = document.getElementsByName('answer-btn')
  const answers = document.getElementsByClassName('content')

  btns.forEach((btn, index) => {
    btn.addEventListener('click', () => {
      if (answers[index].style.visibility === 'hidden' || !answers[index].style.visibility) {
        answers[index].style.visibility = 'visible'
        answers[index].style.maxHeight = '500px'
        answers[index].style.paddingTop = '32px'
        // eslint-disable-next-line no-param-reassign
        btn.firstElementChild.src = arrowUpSvg
      } else {
        answers[index].style.visibility = 'hidden'
        answers[index].style.maxHeight = '0px'
        answers[index].style.paddingTop = '0px'
        // eslint-disable-next-line no-param-reassign
        btn.firstElementChild.src = arrowDownSvg
      }
    })
  })
})
