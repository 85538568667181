const DOMAIN = ".appbooster.com";

const getDateAfterMonth = () => {
  const date = new Date();
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
  return date.toUTCString();
};

(function saveUTMtoCookies() {
  if (!window.location.search.includes("utm")) return;

  const urlParams = new URLSearchParams(window.location.search);
  const paramsObject = {};
  for (let param of urlParams) {
    paramsObject[param[0]] = param[1];
  }

  const queryUTM = JSON.stringify(paramsObject);

  document.cookie = `query_utm=${queryUTM}; domain=${DOMAIN}; Secure; SameSite=None; path=/; expires=${getDateAfterMonth()};`;
})();
