import amplitude from 'amplitude-js/amplitude'

export const fetchLang = () => {
  const lang = window.location.toString().includes('/en') ? 'en' : 'ru'
  localStorage.setItem('lng', lang)

  return lang
}
export const isInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect()

  return (
    bounding.top >= 0
    && bounding.left >= 0
    && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const debounce = callback => () => {
  let timeout

  if (timeout) {
    window.cancelAnimationFrame(timeout)
  }
  timeout = window.requestAnimationFrame(callback)
}

export const initAmplitude = () => {
  amplitude.getInstance().init(
    process.env.APP_AMPLITUDE_API_KEY,
    {
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
    },
  )
}

export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId)
}

export const setUserProperty = (opts) => {
  const identify = new amplitude.Identify()
  if (opts.once) {
    identify.setOnce(opts.key, opts.value)
  } else {
    identify.set(opts.key, opts.value)
  }
  amplitude.getInstance().identify(identify)
}

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties)
}
