const PRICE_TAB_MOTIV = 'priceTabMotiv'
// const PRICE_TAB_BOTS = 'priceTabBots'

document.addEventListener('DOMContentLoaded', () => {
  const tabs = document.getElementsByName('priceTab')
  let activeTabID = PRICE_TAB_MOTIV

  tabs.forEach((tab) => {
    tab.addEventListener('click', () => {
      if (tab.id === activeTabID) {
        return
      }
      activeTabID = tab.id
      tabs.forEach((tab_) => {
        const container = document.getElementById(`${tab_.id}Container`)
        if (tab_.id !== activeTabID) {
          container.style.display = 'none'
          tab_.classList.remove('newPrice_tabs_borderActive')

          return
        }
        container.style.display = 'inherit'
        tab_.classList.add('newPrice_tabs_borderActive')
      })
    })
  })
})
