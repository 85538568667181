import dashboardAnimationData from '../../../assets/animations/dashboard.json'
import trackpositionAnimationData from '../../../assets/animations/trackposition.json'
import compareappsAnimationData from '../../../assets/animations/compareapps.json'
import keywordsAnimationData from '../../../assets/animations/keywords.json'
import trackpositionAnimationDataEn from '../../../assets/animations/trackposition_en.json'
import compareappsAnimationDataEn from '../../../assets/animations/compareapps_en.json'
import keywordsAnimationDataEn from '../../../assets/animations/keywords_en.json'

export default {
  ru: {
    dashboard_animation: dashboardAnimationData,
    trackposition_animation: trackpositionAnimationData,
    compareapps_animation: compareappsAnimationData,
    keywords_animation: keywordsAnimationData,
  },
  en: {
    dashboard_animation: dashboardAnimationData,
    trackposition_animation: trackpositionAnimationDataEn,
    compareapps_animation: compareappsAnimationDataEn,
    keywords_animation: keywordsAnimationDataEn,
  },
}
