import { sendAmplitudeData } from '../utils'

const toggleVisibility = element => () => {
  if (element.length) return element.forEach(el => el.classList.toggle('shown'))

  element.classList.toggle('shown')
}

window.introvert_settings = (II) => {
  II.setSettings({
    disableSubmit: true,
  })
}

document.addEventListener('DOMContentLoaded', () => {
  const overlay = document.querySelector('.js-reg-overlay')
  const actionBtn = document.querySelectorAll('.js-action')
  const form = document.querySelector('.ab__reg-form')
  const dismissBtn = document.querySelector('.js-dismiss')
  const popup = document.querySelectorAll('.ab__reg-popup')

  actionBtn.forEach(btn => btn.addEventListener('click', toggleVisibility(overlay)))
  dismissBtn.addEventListener('click', () => toggleVisibility(overlay)())

  form.addEventListener('submit', (e) => {
    e.preventDefault()

    const { $, getHandler } = window.II
    const handler = getHandler({
      form: $(form),
      params: {
        name: $(form).find('#name').val(),
        phone: $(form).find('#phone').val(),
        comment: $(form).find('#comment').val(),
      },
      handlerAfter: () => {
        toggleVisibility(popup)()
        sendAmplitudeData('personal-requested')
      },
    })

    handler()
    window.dataLayer.push({ event: 'nika_discuss' })
  })
})
