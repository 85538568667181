document.addEventListener('DOMContentLoaded', () => {

  const changeLangElem = document.querySelector('.ab__lang-switcher')

  if (changeLangElem) {
    changeLangElem.addEventListener('click', () => {
      let newLocation
      const path = window.location.pathname
      const query = window.location.search

      if (path === '/') {
        newLocation = '/en'
      }

      window.location.href = newLocation + query
    })
  }
})
