import { tns } from 'tiny-slider/src/tiny-slider'

document.addEventListener('DOMContentLoaded', () => {
  const sliderContainer = document.querySelector('.js-top-slider')

  if (sliderContainer) {
    tns({
      container: '.js-top-slider',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      controls: false,
      mouseDrag: true,
      speed: 800,
    })
  }
})
