import lottie from 'lottie-web'
import animationData from './data'
import { fetchLang, isInViewport, debounce } from '../../utils'

document.addEventListener('DOMContentLoaded', () => {
  const lang = fetchLang()
  const introVideo = document.querySelector('.js-intro-video')
  const animationContainers = [
    'dashboard_animation',
    'trackposition_animation',
    'compareapps_animation',
    'keywords_animation',
  ]

  introVideo.addEventListener('ended', () => {
    introVideo.currentTime = 2.11
    introVideo.play()
  }, false)

  animationContainers.forEach((container) => {
    const containerElem = document.getElementById(container)
    const params = {
      container: containerElem,
      renderer: 'svg',
      name: container,
      loop: true,
      autoplay: false,
      animationData: animationData[lang][container],
    }

    lottie.loadAnimation(params)

    window.addEventListener('scroll', debounce(() => {
      if (isInViewport(containerElem)) {
        lottie.play(container)
      } else {
        lottie.pause(container)
      }
    }))
  })
})
