// TODO: import './components/dashboard' use when dashboard ready
import './components/i18n'
import './components/slider'
import './components/form'
import './components/policy'
import './components/animations'
import './components/amplitude'
import './components/questions'
import './components/priceBlock'
import './saveUTMtoCookies'
